import React, { useEffect, useState, useCallback } from 'react'
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import PaymentForm from '../dialogs/PaymentForm';
import YouTube from 'react-youtube';
import { saveAs } from 'file-saver';
import { AUTH_TOKEN, API_ROUTE, getURLParam, generateAuthToken, copyText }
  from '../modals/Constants';
import { setStorage, getStorage } from '../modals/Storage';
import { useNavigate } from 'react-router-dom';
import '../../MainStyle.css';

function SuperPay() {
  const navigate = useNavigate();
  const qrCode = require('qrcode');
  const [qrCompleteLink, setQrCompleteLink] = useState("");

  const [pageConst, setConstants] = useState({
    pageTitle: "Super Pay",
    inPaymentUpi: "",
    inRefNum: "",
    inPaymentAmount: getURLParam('am'),
    inCrudentials: getURLParam('crudentials'),
    timeLeftInSeconds: -1,
    paymentRefNumber: "",
    paymentStatusCode: 1,
    dataShouldLoad: true,
    isLoadingShow: false,
    paymentDialogShow: false,
    toastDialogShow: false,
    toastMessage: "",
    tabActive: "qr",
  });

  const opts = {
    height: '220',
    width: window.innerWidth,
    playerVars: {
      autoplay: 0,
      showinfo: 0,
      controls: 0,
    },
  }

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === 'visible';

    if (isVisible && getStorage("sessionPayType") == "auto") {
      checkPaymentStatus(true, "");
    }
  }, []);

  const topBarClickAction = (data) => {
    if (data == "enterRefCode") {
      updatePaymentStatusCode(3);
    } else if (data == "back") {
      navigate(-2);
    }
  }

  const updateActiveTab = (data) => {
    setConstants(previousState => {
      return { ...previousState, tabActive: data }
    });
  }

  const submitReferenceNum = () => {
    if (pageConst.inRefNum != "") {
      if (getStorage("sessionPayType") != "manual") {
        checkPaymentStatus(true, pageConst.inRefNum);
      } else {
        submitPayment(pageConst.inRefNum);
      }
    } else {
      updateToastDialogState(true, "Please enter Reference Num!");
    }
  }

  const onInputValChange = (source, data) => {
    if (source == "refnum") {
      setConstants(previousState => {
        return { ...previousState, inRefNum: data }
      });
    }
  }

  function setUpQrCode(upi, amount, note) {
    let qrData = `upi://pay?pa=${upi}&pn=Merchant&cu=INR&am=${amount}&tn=${note}`;

    // Converting the data into base64 
    qrCode.toDataURL(encodeURI(qrData), function (err, code) {
      if (err) {
        return console.log("error occurred")
      }

      // Printing the code
      setQrCompleteLink(code);
    })
  }

  const downloadQRCode = () => {
    if (qrCompleteLink != "") {
      saveAs(qrCompleteLink, 'payment-qrcode.jpg') // Put your image URL here.
    }
  }

  const updateLoadingStatus = (data) => {
    setConstants(previousState => {
      return { ...previousState, isLoadingShow: data }
    });
  }

  const updateGameRuleDialog = (e, data) => {
    if (e != null && e.target.className == "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog") {
      setConstants(previousState => {
        return { ...previousState, paymentDialogShow: data }
      });
    } else if (data == "formsubmitted") {
      generatePayment();

      setConstants(previousState => {
        return { ...previousState, paymentDialogShow: false }
      });
    } else if (data == "true") {
      setConstants(previousState => {
        return { ...previousState, paymentDialogShow: true }
      });
    }
  }

  const updateToastDialogState = (data, msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const submitPayment = (refNumber) => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-submit-payment-bharatpe',
            'AuthToken': generateAuthToken(AUTH_TOKEN),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.response_code == "authorization_error") {
          updateToastDialogState(true, "Authorization Error!");
        } else if (data.response_code == "true") {
          navigate("/submitted", { replace: true });
        } else if (data.response_code == "utr_code_req") {
          updatePaymentStatusCode(3);
        } else if (data.response_code == "invalid_payment_id") {
          navigate("/failed", { replace: true });
        } else if (data.response_code == "already_exist") {
          updateToastDialogState(true, "This Payment was already verified!");
        } else {
          updateToastDialogState(true, "Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(true, "There was a technical issue! Please try again!");
      }
    };


    if (getStorage("sessionPayId")) {
      updateLoadingStatus(true);
      requestAPI(API_ROUTE + "?PAYEE_AMOUNT=" + pageConst.inPaymentAmount + "&PAYMENT_ID=" + getStorage("sessionPayId") + "&REFERENCE_NUM=" + refNumber + "&CRUDENTIALS=" + getURLParam("crudentials"));
    }
  }

  const checkPaymentStatus = (showAlert, refNumber) => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-check-payment-bharatpe',
            'AuthToken': generateAuthToken(AUTH_TOKEN),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.response_code == "authorization_error") {
          updateToastDialogState(true, "Authorization Error!");
        } else if (data.response_code == "true") {
          navigate("/success", { replace: true });
        } else if (data.response_code == "utr_code_req") {
          updatePaymentStatusCode(3);
        } else if (data.response_code == "invalid_payment_id") {
          navigate("/failed", { replace: true });
        } else if (data.response_code == "already_exist") {
          updateToastDialogState(true, "This Payment was already verified!");
        } else if (data.response_code == "false" && showAlert) {
          updateToastDialogState(true, "No Transactions Found. Please try again!");
        } else if (showAlert) {
          updateToastDialogState(true, "Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(true, "There was a technical issue! Please try again!");
      }
    };


    if (getStorage("sessionPayId")) {
      updateLoadingStatus(true);
      requestAPI(API_ROUTE + "?PAYEE_NAME=" + getStorage("sessionPayName") + "&PAYEE_AMOUNT=" + pageConst.inPaymentAmount + "&PAYEE_APP=" +
        getStorage("sessionPayApp") + "&PAYMENT_ID=" + getStorage("sessionPayId") + "&REFERENCE_NUM=" + refNumber + "&CRUDENTIALS=" + getURLParam("crudentials"));
    }
  }

  const generatePayment = () => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-generate-payment-bharatpe',
            'AuthToken': generateAuthToken(AUTH_TOKEN),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "authorization_error") {
          updateToastDialogState(true, "Authorization Error!");
        } else if (data.status_code == "success") {

          setStorage("sessionPayId", data.payment_id);

          const arr = data.payment_upi.split(",");
          const randomIndex = Math.floor(Math.random() * arr.length);
          const randomUPI = arr[randomIndex];

          setConstants(previousState => {
            return { ...previousState, inPaymentUpi: randomUPI }
          });

          setConstants(previousState => {
            return { ...previousState, inPaymentAmount: data.payment_amount }
          });

          setConstants(previousState => {
            return { ...previousState, timeLeftInSeconds: data.payment_time_left }
          });

          setStorage("sessionYtTutorialId", data.payment_yt_tutorial_id);
          setUpQrCode(randomUPI, data.payment_amount, data.payment_id);

        } else if (data.status_code == "invalid_params") {
          updateToastDialogState(true, "Parameters are wrong! Please try again");
        } else {
          updateToastDialogState(true, "Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(true, "There was a technical issue! Please try again!");
      }
    };

    updateLoadingStatus(true);
    requestAPI(API_ROUTE + "?PAYMENT_ID=" + getStorage("sessionPayId") + "&PAYEE_NAME=" + getStorage("sessionPayName") + "&PAYMENT_APP="
      + getStorage("sessionPayApp") + "&RECHARGE_AMOUNT=" + getURLParam('am') + "&CRUDENTIALS=" + getURLParam("crudentials"));
  }

  // function requestData(){
  //   if(pageConst.dataShouldLoad){
  //     setConstants(previousState => {
  //       return { ...previousState, dataShouldLoad: false }
  //     });

  //     generatePayment();
  //   }
  // }

  const copyTextNow = (data) => {
    copyText(data);
    updateToastDialogState(true, "Copied!");
  }

  const updatePaymentStatusCode = (code) => {
    setConstants(previousState => {
      return { ...previousState, paymentStatusCode: code }
    });
  }

  const setUpPayment = () => {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-setup-payment-bharatpe',
            'AuthToken': generateAuthToken(AUTH_TOKEN),
          }
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "authorization_error") {
          updateToastDialogState(true, "Authorization Error!");
        } else if (data.status_code == "success") {

          if (data.payment_mode == "auto") {
            if (data.is_paymentid_valid == "false" || !getStorage("sessionPayName") || !getStorage("sessionPayApp")) {
              setConstants(previousState => {
                return { ...previousState, paymentDialogShow: true }
              });
            } else {
              generatePayment();
            }
          } else if (data.payment_mode == "manual" || !getStorage("sessionPayType")) {
            generatePayment();
          }

          setStorage("sessionPayType", data.payment_mode);

        } else {
          updateToastDialogState(true, "Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(true, "There was a technical issue! Please try again!");
      }
    };

    setConstants(previousState => {
      return { ...previousState, dataShouldLoad: false }
    });

    updateLoadingStatus(true);
    if (getStorage("sessionPayId")) {
      requestAPI(API_ROUTE + "?PAYMENT_ID=" + getStorage("sessionPayId"));
    } else {
      requestAPI(API_ROUTE);
    }
  }

  useEffect(() => {

    if (pageConst.paymentStatusCode == 1 && !pageConst.shouldExit) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    const interval = setInterval(() => {
      if (pageConst.timeLeftInSeconds > 0) {

        if (pageConst.timeLeftInSeconds > 30 && pageConst.timeLeftInSeconds < 350 && pageConst.timeLeftInSeconds % 15 == 0) {
          if (pageConst.paymentStatusCode == 1 && getStorage("sessionPayType") == "auto") {
            checkPaymentStatus(false, "");
          }
        }

        setConstants(previousState => {
          return { ...previousState, timeLeftInSeconds: pageConst.timeLeftInSeconds - 1 }
        });
      } else if (pageConst.timeLeftInSeconds == 0) {
        navigate("/failed", { replace: true });
      }
    }, 1000);


    if (getURLParam('am') != null && getURLParam('am') != "") {

      if (pageConst.dataShouldLoad) {
        setUpPayment();
      }

      // if(!getStorage("sessionPayName") || !getStorage("sessionPayApp")){
      //   setUpPayment();
      // }else{
      //   requestData();
      // }
    } else if (pageConst.paymentStatusCode == 1) {
      updatePaymentStatusCode(2);
    }

    //Clearing the interval
    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }

  }, [pageConst.timeLeftInSeconds, pageConst.paymentStatusCode]);

  return (
    <div className='v-center'>
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
        <TopBar intentData={pageConst} updateState={topBarClickAction} />
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <PaymentForm intentData={pageConst} updateState={updateGameRuleDialog} />

        <div className="col-view mg-t-45">

          <div className={`pd-10-15 bg-l-blue ${pageConst.paymentStatusCode > 1 ? 'hide-v' : ''}`}>
            <p>{getStorage("sessionPayId") != '' ? 'Time: ' + pageConst.timeLeftInSeconds + ' Seconds' : 'Loading...'}</p>
          </div>

          <div className={`col-view w-100 v-center pd-20 ${pageConst.paymentStatusCode > 1 ? 'hide-v' : ''}`}>

            <div className='w-100 col-view pd-10'>
              <div className='row-view'>
                <p className='ft-sz-16'>₹</p>
                <p className='ft-sz-35 mg-l-10'>{getStorage("sessionPayId") != '' ? pageConst.inPaymentAmount : '0'}</p>
              </div>

              <p className='ft-sz-14'>Powered by SuperPay</p>
            </div>

            <div className="tab-slct-v w-100 mg-t-25">
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActive == "qr" ? 'active' : ''}`} onClick={() => updateActiveTab('qr')}>QR Code</div>
              <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActive == "upi" ? 'active' : ''}`} onClick={() => updateActiveTab('upi')}>UPI</div>
            </div>

            <div className={`w-100 col-view mg-t-20 ${pageConst.tabActive != "qr" ? 'hide-v' : ''}`}>
              <div className={`v-center ft-sz-13 ${getStorage("sessionPayType") != "auto" ? 'hide-v' : ''}`}>
                Pay ₹{pageConst.inPaymentAmount} to this QR Code with
                {getStorage("sessionPayApp") == 'paytm' ? <img className="h-w-45 mg-l-5 mg-r-5" src={require('../icons/paytm_icon.png')} /> :
                  getStorage("sessionPayApp") == 'phonepe' ? <img className="h-w-45 mg-l-5 mg-r-5" src={require('../icons/phone_pay_icon.png')} /> :
                    getStorage("sessionPayApp") == 'google pay' ? <img className="h-w-45 mg-l-5 mg-r-5" src={require('../icons/google_pay_icon.webp')} /> :
                      getStorage("sessionPayApp") == 'bhim' ? <div>
                        <img className="h-w-25 mg-l-5 mg-r-5" src={require('../icons/jio_icon.png')} />
                        <img className="h-w-25 mg-l-5 mg-r-5" src={require('../icons/airtel_icon.png')} />
                        <img className="h-w-25 mg-l-5 mg-r-5" src={require('../icons/bhim_icon.png')} /></div> : ''}
              </div>

              <div className={`v-center ft-sz-13 ${getStorage("sessionPayType") != "manual" ? 'hide-v' : ''}`}>
                Pay ₹{pageConst.inPaymentAmount} to this QR Code 👇👇
              </div>

              <div className='w-100 col-view pd-10 mg-t-10 br-5 br-a-l-white'>

                <div className='v-center br-5'>
                  <img className="h-200-p" src={qrCompleteLink} />
                </div>

                <div className='row-view v-center mg-t-10' onClick={() => downloadQRCode()}>
                  <div className='h-w-32 v-center br-50 bg-green'>
                    <img className="h-15-p" src={require('../icons/download-icon-white.png')} />
                  </div>
                  <p className='mg-l-10'>Download</p>
                </div>

                <div className='ft-sz-12 mg-t-15'>
                  Take a screenshot or download the qr code to pay. Don't use old qr code for payment. Your Payment Id is Payment Id: {getStorage("sessionPayId")}
                </div>

              </div>
            </div>

            <div className={`w-100 col-view mg-t-20 ${pageConst.tabActive != "upi" ? 'hide-v' : ''}`}>
              <div className='v-center ft-sz-13'>
                Pay ₹{pageConst.inPaymentAmount} to this UPI ID 👇👇
              </div>

              <div className='ps-rl w-100 pd-10 mg-t-10 br-5 bg-l-grey'>
                <p className='ft-sz-14'>Copy UPI Id</p>
                <p className='ft-sz-18 mg-t-5'>{pageConst.inPaymentUpi}</p>

                <div className='ps-ab ps-tp-50 t-form-y ps-rgt-10 h-w-32 br-50 col-view v-center bg-grey-2' onClick={() => copyTextNow(pageConst.inPaymentUpi)}>
                  <img className="h-15-p" src={require('../icons/copy-icon-white.png')} />
                </div>
              </div>
            </div>

            <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-blue ${getStorage("sessionPayType") != "auto" ? 'hide-v' : ''}`} onClick={() => checkPaymentStatus(true, "")}>
              <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow == false ? 'hide-v' : ''}`}></div>
              <span className={`${pageConst.isLoadingShow == true ? 'hide-v' : ''}`}>Check Payment</span>
            </div>

            <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-blue ${getStorage("sessionPayType") != "manual" ? 'hide-v' : ''}`} onClick={() => updatePaymentStatusCode(3)}>
              <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow == false ? 'hide-v' : ''}`}></div>
              <span className={`${pageConst.isLoadingShow == true ? 'hide-v' : ''}`}>I have Paid</span>
            </div>

            <div className='line-hv-grey mg-t-55'></div>

            <div className='w-100 col-view mg-t-55'>
              <p className='ft-sz-18'>Instruction 👉 How to Pay?</p>
              <p className='mg-t-10'>1) Copy upi or scan qr code.</p>
              <p className='mg-t-10'>2) Pay ₹{pageConst.inPaymentAmount} to copied UPI Id.</p>
              <p className='mg-t-10'>2) Comeback & Click 'Check Payment'.</p>
              <p className='mg-t-10'>2) After verification amount will be credited.</p>

              <p className='ft-sz-18 mg-t-30'>Watch Demo 👉 How to Pay?</p>

              <YouTube videoId={getStorage("sessionYtTutorialId")} opts={opts} className='res-wth v-center mg-t-15' />
            </div>

          </div>

          <div className={`w-100 col-view v-center pd-0-30 mg-t-55 ${pageConst.paymentStatusCode != 2 ? 'hide-v' : ''}`}>
            <img className="h-70-p" src={require('../icons/error-icon.png')} />

            <p className='ft-sz-20 mg-t-15'>Parameters are wrong!!</p>
            <p className='ft-sz-13 mg-t-10'>Note: Payment failed due to wrong parameters.</p>
          </div>

          <div className={`w-100 col-view v-center pd-0-30 mg-t-55 ${pageConst.paymentStatusCode != 3 ? 'hide-v' : ''}`}>
            <img className="h-70-p" src={require('../icons/error-icon.png')} />

            <p className='ft-sz-20 mg-t-15'>Verify your Payment</p>
            <p className='ft-sz-13 mg-t-10'>Payment Id: {getStorage("sessionPayId")}</p>

            <div className='cutm-inp-bx bg-l-grey pd-5-15 br-5 mg-t-20'>
              <img className="h-w-22" src={require('../icons/transaction_icon.png')} />
              <input type="text" className='mg-l-20 ft-sz-18 cutm-inp' placeholder='Enter 12 digit RefNum' autoComplete="new-password" onChange={e => onInputValChange('refnum', e.target.value)}></input>
            </div>

            <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bg-blue`} onClick={() => submitReferenceNum()}>
              <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow == false ? 'hide-v' : ''}`}></div>
              <span className={`${pageConst.isLoadingShow == true ? 'hide-v' : ''}`}>Verify Now</span>
            </div>
            <div className='w-100 v-center h-50-p' onClick={() => updatePaymentStatusCode(1)}>Cancel</div>
            <p className='ft-sz-13 mg-t-10'>Note: It will automatically verify your payment after entering Reference Number or UTR code.</p>

          </div>

        </div>
      </div>
    </div>
  );
}

export default SuperPay;